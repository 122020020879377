import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import type { Analytics } from "firebase/analytics";
import { getAnalytics } from "firebase/analytics";
import type { FirebasePerformance } from "firebase/performance";
import { getPerformance } from "firebase/performance";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { InstrumentationProvider } from "@eleo/components/components/InstrumentationProvider/InstrumentationProvider";
import { EcommerceUser } from "@eleo/core/modules/ecommerce/user/user.ecommerce.models";
import { useGetCurrentEcommerceUserQuery } from "@eleo/core/modules/ecommerce/user/user.ecommerce.hooks";

export type EcommerceAuthContextInterface = {
  user: EcommerceUser | undefined;
  // cart: EcommerceCart | undefined;
};

const EcommerceAuthContext = createContext<EcommerceAuthContextInterface>({
  user: undefined,
});

export const EcommerceAuthProvider = ({ children }: PropsWithChildren) => {
  const [firebaseAnalytics, setFirebaseAnalytics] = useState<
    Analytics | undefined
  >(undefined);
  const [firebasePerformance, setFirebasePerformance] = useState<
    FirebasePerformance | undefined
  >(undefined);

  // const router = useRouter();

  const getCurrentUserQuery = useGetCurrentEcommerceUserQuery();
  const ecommerceUser = getCurrentUserQuery.data;

  // const handleLogout = () => {
  //   const auth = getAuth();
  //
  //   auth.signOut().then(() => {
  //     router.push("/auth/login");
  //   });
  // };

  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    };
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const _perf = getPerformance(app);

    setFirebaseAnalytics(analytics);
    setFirebasePerformance(_perf);

    const auth = getAuth();

    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser && !getCurrentUserQuery.data) {
        getCurrentUserQuery.refetch();
      }
    });
  }, []);

  return (
    <InstrumentationProvider
      firebaseAnalytics={firebaseAnalytics}
      firebasePerformance={firebasePerformance}
    >
      <EcommerceAuthContext.Provider
        value={{
          // logout: handleLogout,
          user: ecommerceUser,
        }}
      >
        {children}
      </EcommerceAuthContext.Provider>
    </InstrumentationProvider>
  );
};

export const useEcommerceUserData = () => useContext(EcommerceAuthContext);
